import { render, staticRenderFns } from "./SalesPersons.vue?vue&type=template&id=c2841930&scoped=true&"
import script from "./SalesPersons.vue?vue&type=script&lang=ts&"
export * from "./SalesPersons.vue?vue&type=script&lang=ts&"
import style0 from "./SalesPersons.vue?vue&type=style&index=0&id=c2841930&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2841930",
  null
  
)

export default component.exports