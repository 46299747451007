












































































































































































































































import { Api, PagedResults, TableState, SalesActiveFilters, SalesPerson, SalesPersonFilters } from '@/edshed-common/api'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({ name: 'SalesPersons' })

export default class SalesPersons extends Mixins(ComponentHelper) {
  private showCreateSalesPersonModal: boolean = false
  private showEditSalesPersonModal: boolean = false
  private createLoading: boolean = false
  private editLoading: boolean = false
  private tableLoading: boolean = false

  selectedActive: SalesActiveFilters | null = 'Active'

  salesPersons: PagedResults<SalesPerson> = {
    items: [],
    total: 0
  }

  blankSalesPerson (): SalesPerson {
    return {
      id: 0,
      name: '',
      is_active: 1
    }
  }

  salesPerson: SalesPerson = this.blankSalesPerson()

  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'name',
    dir: 'desc',
    term: ''
  }

  SalesActiveFilters = SalesActiveFilters

  get canSubmitForm () {
    return this.salesPerson.name.length >= 5
  }

  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getSalesPersons()
    }
  }

  @Watch('selectedActive')
  onActiveFilterChange () {
    this.table.page = 1
    this.getSalesPersons()
  }

  toggleCreateModal () {
    this.showCreateSalesPersonModal = !this.showCreateSalesPersonModal

    if (!this.showCreateSalesPersonModal) {
      this.salesPerson = this.blankSalesPerson()
    }
  }

  toggleEditModal (person: SalesPerson | null) {
    if (person) {
      this.salesPerson = { ...person }
    } else {
      this.salesPerson = this.blankSalesPerson()
    }
    this.showEditSalesPersonModal = !this.showEditSalesPersonModal
  }

  async createSalesPerson () {
    try {
      this.createLoading = true
      await Api.createSalesPerson(this.salesPerson.name)
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error creating Sales Person',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.createLoading = false
      this.toggleCreateModal()
      this.getSalesPersons()
    }
  }

  async getSalesPersons () {
    try {
      this.tableLoading = true
      const params: SalesPersonFilters = {
      }
      if (this.selectedActive) {
        params.active = this.selectedActive
      }
      this.salesPersons = await Api.getSalesPersons({ skip: (this.table.page - 1) * this.table.perPage, take: this.table.perPage, sort: this.table.sort, dir: this.table.dir, term: this.table.term }, params)
      this.tableLoading = false
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error getting Sales Person(s)',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  async editSalesPerson () {
    try {
      await Api.editSalesPerson({ ...this.salesPerson })

      this.$buefy.toast.open({
        message: 'Data updated',
        type: 'is-success',
        position: 'is-bottom'
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error editing Sales Person(s)',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.toggleEditModal(null)
      this.getSalesPersons()
    }
  }

  confirmDeleteSalesPerson (person: SalesPerson) {
    this.$buefy.dialog.confirm({
      title: 'Delete Sales Person',
      message: `Are you sure you wish to delete ${person.name} as a sales person? This will remove them from every sales/merch record.`,
      cancelText: 'Close',
      confirmText: 'Delete',
      type: 'is-danger',
      onConfirm: async () => {
        await this.deleteSalesPerson(person)
      }
    })
  }

  confirmDeactivateSalesPerson (person: SalesPerson) {
    this.$buefy.dialog.confirm({
      title: 'Deactivate Sales Person',
      message: `Are you sure you wish to deactivate ${person.name} as a sales person? They can be reactivated later if required.`,
      cancelText: 'Close',
      confirmText: 'Deactivate',
      type: 'is-warning',
      onConfirm: async () => {
        await this.deactivateSalesPerson(person)
      }
    })
  }

  async deactivateSalesPerson (person: SalesPerson) {
    try {
      await Api.deactivateSalesPerson(person.id)

      this.$buefy.toast.open({
        message: 'Data updated',
        type: 'is-success',
        position: 'is-bottom'
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error deactivating Sales Person(s)',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.getSalesPersons()
    }
  }

  async reactivateSalesPerson (person: SalesPerson) {
    try {
      await Api.reactivateSalesPerson(person.id)

      this.$buefy.toast.open({
        message: 'Data updated',
        type: 'is-success',
        position: 'is-bottom'
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error reactivating Sales Person(s)',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.getSalesPersons()
    }
  }

  async deleteSalesPerson (person: SalesPerson) {
    try {
      await Api.deleteSalesPerson(person)

      this.$buefy.toast.open({
        message: 'Data updated',
        type: 'is-success',
        position: 'is-bottom'
      })
    } catch (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: 'Error deleting Sales Person(s)',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.getSalesPersons()
    }
  }

  onSortChange () {
    this.table.dir = this.table.dir === 'asc' ? 'desc' : 'asc'
    this.getSalesPersons()
  }

  onPageChange (page: number) {
    this.table.page = page
    this.getSalesPersons()
  }

  async searchDidChange () {
    this.table.page = 1
    await this.getSalesPersons()
  }
}
